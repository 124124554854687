<template>
  <CRow class="w-100 justify-content-center">
    <CCol col="12" sm="10" md="10" lg="8" xl="7">
      <CCard
          v-if="this.$store.state.organization.id !== 0 && can('provider_user')"
      >
        <CCardBody>
          <h2 class="pb-3">Редагування даних</h2>
          <CAlert v-if="success" show color="success">Ваші дані змінено!</CAlert>
          <CAlert v-if="error.failed" show color="danger">{{ error.failed_message }}</CAlert>
          <CAlert v-if="error.failed_phone" show color="danger">{{ error.failed_phone_message }}</CAlert>

          <div class="form-item" :class="{'errorInput': $v.update_provider.brand.$error}">
            <CInput
                placeholder="Бренд"
                v-model="update_provider.brand"
                @change="$v.update_provider.brand.$touch()"
                :class="{
                'error':$v.update_provider.brand.$error
              }"
            >
              <template #prepend-content>
                <CIcon name="cil-user"/>
              </template>
            </CInput>
            <small v-if="!$v.update_provider.brand.required" class="form-text errorText w-100">Необхідно заповнити
              Бренд.</small>
          </div>

          <div class="form-item" :class="{'errorInput': $v.update_provider.website.$error}">
            <CInput
                placeholder="Веб-сторінка"
                v-model="update_provider.website"
                @change="$v.update_provider.website.$touch()"
                :class="{
                'error':$v.update_provider.website.$error
              }"
            >
              <template #prepend-content>
                <CIcon name="cil-user"/>
              </template>
            </CInput>
            <small v-if="!$v.update_provider.website.required" class="form-text errorText w-100">Необхідно заповнити
              Веб-сторінка.</small>
          </div>

          <div class="form-item" :class="{'errorInput': $v.update_provider.accounting_contacts.$error}">
            <CRow col="6" sm="4" md="2" class="mb-3 mb-xl-3" align-horizontal="center">
              Телефон абонентського відділу/бухгалтерії
            </CRow>
            <CRow v-for="(phone, index) in update_provider.accounting_contacts" :key="phone.id">
              <CCol>
                <CInput
                    placeholder="Телефон"
                    :value="phone"
                    v-c-tooltip="{content: 'Введіть номер телефона - 12 цифр',placement: 'top'}"
                    @input="(val) => saveValue(index, val, 'accounting')"
                    v-mask="[ '+38 (###) ###-##-##']"
                    @change="$v.update_provider.accounting_contacts.$each[index].$touch()"
                    :class="{'error': $v.update_provider.accounting_contacts.$each[index].$error}"
                >
                  <template #prepend-content>
                    <CIcon name="cil-phone"/>
                  </template>
                  <template v-if="update_provider.accounting_contacts.length > 1" #append>
                    <CButton color="danger" @click="removeElement(index, 'accounting')">Видалити</CButton>
                  </template>
                </CInput>
              </CCol>
              <small v-if="!$v.update_provider.accounting_contacts.$each[index].required ||
              !$v.update_provider.accounting_contacts.$each[index].$params.maxLength ||
              !$v.update_provider.accounting_contacts.$each[index].$params.minLength"
                     class="form-text errorText w-100 ml-3">Необхідно
                заповнити номер телефона або його видалити.</small>
            </CRow>
            <small v-if="!$v.update_provider.accounting_contacts.$params.arrayNotEmpty"
                   class="form-text errorText w-100 ml-3">Необхадно заповнити поле.</small>
            <CRow class="align-items-center">
              <CCol col="12" sm="12" md="12" lg="12" xl="12" class="mb-3 mb-xl-3 text-right">
                <CButton color="primary" @click="addNumber('accounting')">Додати номер</CButton>
              </CCol>
            </CRow>
          </div>

          <div class="form-item" :class="{'errorInput': $v.update_provider.technical_support_contacts.$error}">
            <CRow col="6" sm="4" md="2" class="mb-3 mb-xl-3" align-horizontal="center">
              Технічна підтримка
            </CRow>
            <CRow v-for="(phone, index) in update_provider.technical_support_contacts" :key="phone.id">
              <CCol class="text-left">
                <CInput
                    placeholder="Телефон"
                    :value="phone"
                    v-c-tooltip="{content: 'Введіть номер телефона - 12 цифр',placement: 'top'}"
                    @input="(val) => saveValue(index, val, 'technical')"
                    v-mask="[ '+38 (###) ###-##-##']"
                    @change="$v.update_provider.technical_support_contacts.$each[index].$touch()"
                    :class="{'error': $v.update_provider.technical_support_contacts.$each[index].$error}"
                >
                  <template #prepend-content>
                    <CIcon name="cil-phone"/>
                  </template>
                  <template v-if="update_provider.technical_support_contacts.length > 1" #append>
                    <CButton color="danger" @click="removeElement(index, 'technical')">Видалити</CButton>
                  </template>
                </CInput>
              </CCol>
              <small v-if="!$v.update_provider.technical_support_contacts.$each[index].required ||
              !$v.update_provider.technical_support_contacts.$each[index].$params.maxLength ||
              !$v.update_provider.technical_support_contacts.$each[index].$params.minLength"
                     class="form-text errorText w-100 ml-3">Необхідно
                заповнити номер телефона або його видалити.</small>

            </CRow>
            <small v-if="!$v.update_provider.technical_support_contacts.$params.arrayNotEmpty"
                   class="form-text errorText w-100 ml-3">Необхадно заповнити поле.</small>
            <CRow class="align-items-center">
              <CCol col="12" sm="12" md="12" lg="12" xl="12" class="mb-3 mb-xl-3 text-right">
                <CButton color="primary" @click="addNumber('technical')">Додати номер</CButton>
              </CCol>
            </CRow>
          </div>

          <div class="form-item" :class="{'errorInput': $v.update_provider.accounting_emails.$error}">
            <CRow col="6" sm="4" md="2" class="mb-3 mb-xl-3" align-horizontal="center">
              Email - Бухгалтерії
            </CRow>
            <CRow v-for="(email, index) in update_provider.accounting_emails" :key="email.id">
              <CCol>
                <CInput
                    placeholder="Email"
                    autocomplete="email"
                    :value="email"
                    @input="(val) => saveEmailValue(index, val, 'accounting')"
                    @change="$v.update_provider.accounting_emails.$each[index].$touch()"
                    :class="{'error': $v.update_provider.accounting_emails.$each[index].$error}"
                >
                  <template #prepend-content>
                    <CIcon name="cil-phone"/>
                  </template>
                  <template v-if="update_provider.accounting_emails.length > 1" #append>
                    <CButton color="danger" @click="removeEmailElement(index, 'accounting')">Видалити</CButton>
                  </template>
                </CInput>
              </CCol>

              <small v-if="!$v.update_provider.accounting_emails.$each[index].required ||
              !$v.update_provider.accounting_emails.$each[index].$params.maxLength "
                     class="form-text errorText w-100 ml-3">Необхідно
                заповнити Email або його видалити.</small>
            </CRow>
            <small v-if="!$v.update_provider.accounting_emails.$params.arrayNotEmpty"
                   class="form-text errorText w-100 ml-3">Необхадно заповнити поле.</small>
            <CRow class="align-items-center">
              <CCol col="12" sm="12" md="12" lg="12" xl="12" class="mb-3 mb-xl-3 text-right">
                <CButton color="primary" @click="addEmail('accounting')">Додати Email</CButton>
              </CCol>
            </CRow>
          </div>

          <div class="form-item" :class="{'errorInput': $v.update_provider.technical_emails.$error}">
            <CRow col="6" sm="4" md="2" class="mb-3 mb-xl-3" align-horizontal="center">
              Email - Технічної підтримки
            </CRow>
            <CRow v-for="(email, index) in update_provider.technical_emails" :key="email.id">
              <CCol>
                <CInput
                    placeholder="Email"
                    autocomplete="email"
                    :value="email"
                    @input="(val) => saveEmailValue(index, val, 'technical')"
                    @change="$v.update_provider.technical_emails.$each[index].$touch()"
                    :class="{'error': $v.update_provider.technical_emails.$each[index].$error}"
                >
                  <template #prepend-content>
                    <CIcon name="cil-phone"/>
                  </template>
                  <template v-if="update_provider.technical_emails.length > 1" #append>
                    <CButton color="danger" @click="removeEmailElement(index, 'technical')">Видалити</CButton>
                  </template>
                </CInput>
              </CCol>
              <small v-if="!$v.update_provider.technical_emails.$each[index].required ||
              !$v.update_provider.technical_emails.$each[index].$params.maxLength "
                     class="form-text errorText w-100 ml-3">Необхідно
                заповнити Email або його видалити.</small>
            </CRow>
            <small v-if="!$v.update_provider.technical_emails.$params.arrayNotEmpty"
                   class="form-text errorText w-100 ml-3">Необхадно заповнити поле.</small>
            <CRow class="align-items-center">
              <CCol col="12" sm="12" md="12" lg="12" xl="12" class="mb-3 mb-xl-3 text-right">
                <CButton color="primary" @click="addEmail('technical')">Додати Email</CButton>
              </CCol>
            </CRow>
          </div>

          <table>
            <tbody>
            <tr>
              <th>Надання магістральних каналів</th>
              <td col="12" sm="12" md="12" lg="12" xl="12">
                                <span class="ml-5">
                                    <CSwitch
                                        label-off="Ні"
                                        label-on="Так"
                                        class="mx-1 ml-5"
                                        color="primary"
                                        :checked.sync="update_provider.is_pipeline"
                                    /></span>

              </td>
            </tr>
            <br>
            <tr>
              <th>Електронний документообіг <a href="https://paperless.com.ua" target="_blank">PaperLess</a></th>
              <td>
                                <span class="ml-5">
                                    <CSwitch
                                        label-off="Ні"
                                        label-on="Так"
                                        class="mx-1 ml-5"
                                        color="primary"
                                        :checked.sync="update_provider.is_paper_less"
                                    />
                                </span>
              </td>
            </tr>
            </tbody>
          </table>
        </CCardBody>
        <CCardFooter>
          <CRow class="align-items-center">
            <CCol col="6" sm="8" md="10" lg="8" xl="9" class="mb-3 mb-xl-0 text-left">
              <CButton color="secondary" @click="goBack">Назад</CButton>
            </CCol>

            <CCol col="6" sm="4" md="4" lg="4" xl="3" class="mb-3 mb-xl-0 text-right">
              <CButton
                  v-if="loading_btn_edit_provider"
                  color="primary"
                  class="btn-block"
                  disabled
              >
                <div class="  align-items-center">
                  <CSpinner color="default" size="sm"/>
                </div>
              </CButton>
              <CButton
                  v-else
                  class="btn-block"
                  color="primary"
                  @click="save">Зберегти
              </CButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from "axios";
import {mask} from 'vue-the-mask'
import {required, email, maxLength, requiredIf, minLength} from 'vuelidate/lib/validators'
import {can} from "@/rules/permissions";

const arrayNotEmpty = (value) => Array.isArray(value) && !!value.length;


export default {
  directives: {mask},
  name: 'EditProviderOrganization',
  data: () => {
    return {
      error: {
        failed: false,
        failed_message: 'Щось пішло не так... Спробуйте пізніше',
        failed_phone: false,
        failed_phone_message: 'Заповніть поле із телефоном коректно!',
        failed_tehnical_support: false,
      },

      success: false,
      update_provider: {
        brand: "",
        website: "",
        accounting_contacts: [],
        technical_support_contacts: [],
        is_pipeline: false,
        is_paper_less: false,
        accounting_emails: [],
        technical_emails: [],
      },

      loading_btn_edit_provider: false,
    }
  },
  validations: {
    update_provider: {
      brand: {
        required,
        type: String
      },
      website: {required},
      accounting_contacts: {
        required,
        arrayNotEmpty,
        $each: {
          required: requiredIf(val => val),
          type: String,
          minLength: minLength(19),
          maxLength: maxLength(19)
        }
      },
      technical_support_contacts: {
        required,
        arrayNotEmpty,
        $each: {
          required: requiredIf(val => val),
          type: String,
          minLength: minLength(19),
          maxLength: maxLength(19)
        }
      },
      accounting_emails: {
        required,
        arrayNotEmpty,
        $each: {
          required: requiredIf(val => val),
          type: String,
          email,
          maxLength: maxLength(350)
        }
      },
      technical_emails: {
        required,
        arrayNotEmpty,
        $each: {
          required: requiredIf(val => val),
          type: String,
          email,
          maxLength: maxLength(350)
        }
      }
    }
  },
  methods: {
    can,
    goBack() {
      this.$router.go(-1)
    },
    addNumber(name) {
      if (name === 'accounting') {
        this.update_provider.accounting_contacts.push('');
        return
      }
      this.update_provider.technical_support_contacts.push('');
    },
    saveValue(index, value, name) {
      if (name === 'accounting') {
        this.$set(this.update_provider.accounting_contacts, index, value)
        return
      }
      this.$set(this.update_provider.technical_support_contacts, index, value)
    },
    removeElement(index, name) {
      if (name === 'accounting') {
        this.update_provider.accounting_contacts.splice(index, 1);
        return
      }
      this.update_provider.technical_support_contacts.splice(index, 1);
    },
    addEmail(name) {
      if (name === 'accounting') {
        this.update_provider.accounting_emails.push('');
        return
      }
      this.update_provider.technical_emails.push('');
    },
    saveEmailValue(index, value, name) {
      if (name === 'accounting') {
        this.$set(this.update_provider.accounting_emails, index, value)
        return
      }
      this.$set(this.update_provider.technical_emails, index, value)
    },
    removeEmailElement(index, name) {
      if (name === 'accounting') {
        this.update_provider.accounting_emails.splice(index, 1);
        return
      }
      this.update_provider.technical_emails.splice(index, 1);
    },
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      let self = this;
      self.loading_btn_edit_provider = true;


      let accounting_contacts = self.update_provider.accounting_contacts.join(',');
      let technical_support_contacts = self.update_provider.technical_support_contacts.join(',');
      let accounting_emails = self.update_provider.accounting_emails.join(',');
      let technical_emails = self.update_provider.technical_emails.join(',');

      axios.put(
          '/api/account/' + self.$store.state.organization.id, {
            brand: self.update_provider.brand,
            website: self.update_provider.website,
            accounting_contacts: accounting_contacts,
            technical_support_contacts: technical_support_contacts,
            accounting_emails: accounting_emails,
            technical_emails: technical_emails,
            is_pipeline: self.update_provider.is_pipeline,
            is_paper_less: self.update_provider.is_paper_less,
          })
          .then(function () {
            self.$store.dispatch('GET_ORGANIZATION');
            self.loading_btn_edit_provider = false;
            self.success = true;
            setTimeout(function () {
              self.$router.push({path: `/provider`})
            }, 900);
          })
          .catch(function () {
            self.loading_btn_edit_provider = false;
            self.error.failed = true;
          });
    },
  },
  mounted() {
    this.update_provider.brand = this.$store.state.organization.brand
    this.update_provider.website = this.$store.state.organization.website
    this.update_provider.accounting_contacts = this.$store.state.organization.accounting_contacts
    this.update_provider.technical_support_contacts = this.$store.state.organization.technical_support_contacts
    this.update_provider.accounting_emails = this.$store.state.organization.accounting_emails
    this.update_provider.technical_emails = this.$store.state.organization.technical_emails
    this.update_provider.is_pipeline = this.$store.state.organization.is_pipeline
    this.update_provider.is_paper_less = this.$store.state.organization.is_paper_less
  },
}
</script>
<style lang="scss">
.form-item .errorText {
  display: none;
  //font-size: 1rem !important;
  color: #cb203d;
}

.form-item {
  &.errorInput .errorText {
    display: block;
  }
}

.error {
  border-color: #ff002a !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-radius: .3rem;
}
</style>